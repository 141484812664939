import React from 'react';
import HomeLayout from '../layouts/home_layout';
import SignupForm from "../mixins/sign-up-form";
import "../../assets/css/internal/register.css"
const Register = () => {
  return (
    <HomeLayout content={<SignupForm />} />
  );
};

export default Register;

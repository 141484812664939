import React, { Component } from 'react';
import { Field } from 'formik';
import $ from "jquery";
class QuestionComponent extends Component {
    constructor(props) {
        super(props);
        
    this.state={}
    }
    question_completed = (e) => {
        const parent = $(e.target).closest('.q_item');
        const uncle_title = parent.find('.q_title');
        uncle_title.addClass("completed");
        const q_details = parent.find('.q_details');
        q_details.slideUp();
        const sibling = parent.next('.q_item');
        const sibling_q_details = sibling.find('.q_details');
        sibling_q_details.slideDown();
    }
    question_Open = (e) => {
        const parent = $(e.target).closest('.q_item');
        const q_details = parent.find('.q_details');
        q_details.slideToggle();
        const uncle_title = parent.find('.q_title');
        uncle_title.toggleClass("active");
    }

    render() {
    
        
        return (
            

                    <div className="q_item">
                        <div className="q_title active" onClick={this.question_Open}>
                            <label>{this.props.question}</label> <span className="arrow"></span>
                        </div>
                        <div className="q_details shadow-lg text-center">
                        <div className="q_details_pair" >
                        
                                        <td className='' >
                                            <Field
                                            onClick={this.question_completed}
                                                type="radio"
                                                name={`q-${this.props.questionNumber}`}
                                                value="Yes"
                                            />
                                            <label>Yes</label>
                                        </td>
                                        <td>
                                            <Field
                                            onClick={this.question_completed}
                                                type="radio"
                                                name={`q-${this.props.questionNumber}`}
                                                value="No"
                                            />
                                            <label>No</label>
                                        </td>
                                </div>
                        </div>
                    </div>

        );
    }
}

export default QuestionComponent;

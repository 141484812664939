import './assets/css/style.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';

import PrivacyPolicy from './views/pages/privacy_policy';
import Register from './views/pages/register';
import Home from './views/pages/home';
function App() {
  return (
    <BrowserRouter>
    <Routes>  
    <Route
    path="/"
     
    element={<Home />} />
    <Route path="/register" element={<Register />} />
    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
    
    
          </Routes>
    </BrowserRouter>
  );
}

export default App;

import { signInWithEmailAndPassword} from "firebase/auth";
import { toast } from 'react-toastify';


//Create a user class with methods to get set, modify user data in firebase, upload files, and get files, sign in the user, and sign out the user. 
class User{
    // The URL of the Cloud Function that we will be calling.
        
        constructor (credentials) {
            this.assistantUrl='https://us-central1-tele-jobs-pro-llc.cloudfunctions.net/copilot?option=';
           Object.assign(this,  credentials);
           
        }
    async login(auth,email, password){
        this.userCredential = signInWithEmailAndPassword(auth, email, password).catch(error => {
            toast.error(error);
            throw new Error(error);
        });
        this.userCredential = await this.user;
    }
    static logout(auth){
        auth.signOut();
    }
    static getCurrentUser(auth){
        return auth.currentUser;
    }
    updateUser(data){
        //update user collection in firebase
        //add collectionName to data
        
        data.collectionName = `users`
        //add userUid to data from firestore returned uid
   
        //add created and updated to data timestamps
        data.createdAt= new Date().toISOString();
        data.updatedAt= new Date().toISOString();
        // We then make a fetch request to the Cloud Function.
         fetch((this.assistantUrl + "updateUser"), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();  // or response.text() if the response is not JSON
        }).catch(error => {
            toast.error(error + " during updateUser");
            throw new Error(error);
        });
    }
    

}
export default User;
import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import TJPHandShake from '../../assets/imgs/page/home/TJPHandShake.png';
import TJPFrontPageAgent from '../../assets/imgs/page/home/TJPFrontPageAgent.png';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        navigate('/register?email='+event.target.email.value, { replace: true });
      
    };

    return (
        <div className="section-box">
            <div className="banner-hero banner-homepage5">
                <Container className="mt-50">
                    <Row>
                        <Col lg={8}>
                            <span className="text-heading-4 color-gray-400">
                                We Offer You More Control!
                            </span>
                            <h1 className="text-display-2 mt-30">
                                Work From Home Made Easy
                            </h1>
                            <p className="text-body-lead-large color-gray-500 mt-40 pr-40">
                                Work from home is here to stay! <br className="d-lg-block d-none" />
                            </p>
                            <div className="mt-40">
                                <ul className="list-icon-3">
                                    <li className="text-body-text-md">You Pick Your Hours</li>
                                    <li className="text-body-text-md">Minimal Supervision</li>
                                    <li className="text-body-text-md">On The Job Training</li>
                                    <li className="text-body-text-md">You Pick your Job</li>
                                    <li className="text-body-text-md">Hourly Rates Always On</li>
                                    <li className="text-body-text-md">Easy To Switch Program</li>
                                </ul>
                            </div>
                            <div className="mt-40 box-mw-610">
                                <div className="form-newsletter-2">
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className="input-newsletter email-reg"
                                                type="email"
                                                name="email"
                                                placeholder="Enter your email ..."
                                            />
                                        </Form.Group>
                                        <Button
                                            className="btn btn-newsletter email-reg-button icon-arrow-right-white"
                                            type="submit"
                                        >
                                            Apply Now!
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="d-none d-lg-block">
                            <div className="banner-imgs">
                                <div className="block-3 shape-3">
                                    <img
                                        src={TJPHandShake}
                                        alt="TJP's handshake"
                                        loading="lazy"
                                    />
                                </div>
                                <img
                                    className="img-responsive shape-1"
                                    alt="TJP's Agent"
                                    src={TJPFrontPageAgent}
                                    loading="lazy"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default HeroSection;

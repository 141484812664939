import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input, FormGroup, Button, Label, Container, CardBody, CardHeader, Card, Row, Col } from 'reactstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import QualifyingQuestions from "../mixins/qualifying-questions";
import "../../assets/js/internal/register.js"
import $ from "jquery";
import { initializeApp } from "firebase/app";
import { useLocation } from 'react-router-dom';

import { createUserWithEmailAndPassword, getAuth , signInWithEmailAndPassword} from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";

import user from "../../assets/user_module.js";
const firebaseConfig = {
  apiKey: "AIzaSyAjr2qggw8nCgntTWXRWXbq0H3TtK840-Q",
  authDomain: "tele-jobs-pro-llc.firebaseapp.com",
  projectId: "tele-jobs-pro-llc",
  storageBucket: "tele-jobs-pro-llc.appspot.com",
  messagingSenderId: "542175067933",
  appId: "1:542175067933:web:5934b5ea91388d73982bf4"
};
//eslint-disable-next-line
const signIn= async (email, password)=>{
  const auth = getAuth(app);
  signInWithEmailAndPassword(auth, email, password)

}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const storage = getStorage(app);
const SignUpForm = () => {


  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
    password: Yup.string()
      .min(8, 'Must be 8 characters or more')
      .required('Required'),
    password2: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
    legalName: Yup.string()
      .required('Required'),
    file: Yup.mixed()
      .required('Required'),
    linkedin: Yup.string()
  });

  if(user.getCurrentUser(auth)){
    toast.error("You are logged in");

  }

  const handleSubmit = async (values, { setSubmitting }) => {
    const { email, password, legalName, file, linkedin } = values;
      
      let userInstance=null;
      
      createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
        // Signed in 
        
        toast.success("Adding your information");
         
        
         userInstance = new user(userCredential.user);

        
        // ...
        return userInstance;
      }).then((userInstance)=>{
        
        const fileRef = ref(storage, `Resumes/user-${userInstance.uid}/${file.name}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', 
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
              default:
                break;
            }

          }, 
          (error) => {
            // Handle unsuccessful uploads
            console.log(error);

          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              userInstance.updateUser({"data": {
                "userUid": userInstance.uid,
                "legalName": legalName,
                "resume": downloadURL,
                "linkedin": linkedin,
                "questions": {
                  "q1": $('[name="#q-0"]').val() === 'Yes' ? true : false,
                  "q2": $('[name="#q-1"]').val() === 'Yes' ? true : false,
                  "q3": $('[name="#q-2"]').val() === 'Yes' ? true : false,
                  "q4": $('[name="#q-3"]').val() === 'Yes' ? true : false,
                  "q5": $('[name="#q-4"]').val() === 'Yes' ? true : false,
                  "q6": $('[name="#q-5"]').val() === 'Yes' ? true : false,
                  "q7": $('[name="#q-6"]').val() === 'Yes' ? true : false,
                }}});
                toast.success("We will be in touch soon!");
            });
          }
        );
    

      })
      


      
      
    
    setSubmitting(false);
  
  }



  function validator() {
    const object = $("#password")
    //regex

    const length = object.val() ? object.val().length > 8 : false;
    const uppercaseR = /[A-Z]{1}/.test(object.val());
    const lowercase = /[a-z]{1}/.test(object.val());
    const digit = /\d{1}/.test(object.val());
    setColor(length, ".psl");
    setColor(uppercaseR, ".psU");
    setColor(lowercase, ".pslo");
    setColor(digit, ".psd");
  }

  function setColor(boolean, object) {
    object = $(object);
    if (boolean) {
      object.css("color", "#77CBB9");
    } else {
      object.css("color", "initial");
    }
  }

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


  validator();


  return (
    <>

      <Container className="mt-5 pt-lg-7">
        <Row className="justify-content-center">
          <Col lg="5">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white pb-5">
                <div className="text-muted text-center mb-3">
                  <small>Sign up with</small>
                </div>
                <div className="text-center">

                  <Button
                    className="btn-neutral btn-icon ml-1"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={require("../../assets/imgs/page/register/google.svg").default}
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5 bg-white">
                <div className="text-center text-muted mb-4">
                  <small>Or sign up with credentials</small>
                </div>

                <Formik
                  initialValues={{ email: useQuery().get("email")
                  , password: '', legalName: '', file: null, linkedin: '' }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label for="legalName">Legal Name</Label>
                        <Input
                          id="legalName"
                          name="legalName"
                          type="text"
                          value={values.legalName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={errors.legalName && touched.legalName}
                        />
                        {errors.legalName && touched.legalName && (
                          <ErrorMessage name="legalName" component="small" color="danger" />
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="email">Email Address</Label>
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={errors.email && touched.email}
                        />
                        {errors.email && touched.email && (
                          <ErrorMessage name="email" component="small" color="danger" />
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="file">Resume</Label>
                        <Input
                          id="file"
                          name="file"
                          type="file"
                          onChange={(event) => {
                            setFieldValue("file", event.currentTarget.files[0]);
                          }}
                          onBlur={handleBlur}
                          invalid={errors.file && touched.file}
                        />
                          {errors.file && touched.file && (
                            <ErrorMessage name="file" component="small" color="danger" />
                          )}
                    </FormGroup>
                      <FormGroup>
                        <Label for="linkedin">Linkedin URL</Label>
                        <Input
                          id="linkedin"
                          name="linkedin"
                          type="text"
                          value={values.linkedin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={errors.linkedin && touched.linkedin}
                        />
                        {errors.linkedin && touched.linkedin && (
                          <ErrorMessage name="linkedin" component="small" color="danger" />
                        )}
                      </FormGroup>
                      <FormGroup>
                        <QualifyingQuestions 
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          
                          touched={touched}
                          errors={errors}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          value={values.password}
                          onChange={handleChange}
                          onChangeCapture={validator}
                          onBlur={handleBlur}
                          onClick={() => $("#requirements").show("slow")}
                          invalid={errors.password && touched.password}
                        />
                        <div id="requirements" className="hide my-3">
                          <ul>
                            <li className="psl">* At least 8 characters long</li>
                            <li className="psU">* Must contain at least one UPPERCASE</li>
                            <li className="pslo">* Must contain at least one lowercase</li>
                            <li className="psd">* Must contain at least one digit</li>
                          </ul>
                        </div>
                        {errors.password && touched.password && (
                          <ErrorMessage name="password" component="small" color="danger" />
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="password2">Confirm Password</Label>
                        <Input
                          id="password2"
                          name="password2"
                          type="password"
                          value={values.password2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={errors.password2 && touched.password2}
                        />
                        {errors.password2 && touched.password2 && (
                          <ErrorMessage name="password2" component="small" color="danger" />
                        )}
                      </FormGroup>
                      <div className="text-muted font-italic">
                        <small>
                          password strength required:{" "}<span className="text-success font-weight-700">
                            strong
                          </span>
                        </small>
                      </div>
                      <Row className="my-4">
                        <Col xs="12">
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheckRegister"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheckRegister"
                            >
                              <span>
                                I agree with the{" "}<a
                                  className='required'
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Privacy Policy
                                </a>
                              </span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Create account
                        </Button>
                      </div>
                    </Form>
                  )}

                </Formik>



              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};
export default SignUpForm;
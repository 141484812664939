import React from "react";
import UsefulLinks from "./useful_links";
import HorizontalLogoColor from '../assets/imgs/brand/TJPColorHoLogo.png';
const Footer = () => {
  return (
    <footer className="footer mt-50">
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div className="col-md-4 col-sm-6 text-center text-md-start">
              <a href="index.html">
                <img
                  alt="Tele Jobs Pro, LLC's Logo"
                  src={HorizontalLogoColor}
                />
              </a>
            </div>
            <div className="col-md-8 col-sm-6 text-center text-md-end">
              <span className="color-gray-900 text-heading-6 mr-30 text-mb-sm-20">
                Ready to get started?
              </span>
              <a className="btn btn-square" href="/register">
                Create an Account
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 width-20 mb-30">
            <h4 className="text-heading-5">Contact</h4>
            <div className="mt-20 text-body-text color-gray-600 mb-20">
              30 N Gould St, Sheridan WY
            </div>
            <div className="mt-20 text-body-text color-gray-600">
              (239) 555-0108
            </div>
            <div className="text-body-text color-gray-600">
              contact@telejobspro.com
            </div>
          </div>
          <div className="col-lg-3 width-20 mb-30">
            <h4 className="text-heading-5">About Us</h4>
            <ul className="menu-footer mt-20">
              <li>
                <a href="/#">Mission &amp; Vision</a>
              </li>
              <li>
                <a href="/#">Our Team</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/#">Press &amp; Media</a>
              </li>
              <li>
                <a href="/#">Advertising</a>
              </li>
              <li>
                <a href="/#">Testimonials</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 width-20 mb-30">
            <h4 className="text-heading-5">Discover</h4>
            <ul className="menu-footer mt-20">
              <li>
                <a href="/blog">Our Blog</a>
              </li>
              <li>
                <a href="/pricing">Plans &amp; Pricing</a>
              </li>
              <li>
                <a href="/#">Knowledge Base</a>
              </li>
              <li>
                <a href="/#">Cookie Policy</a>
              </li>
              <li>
                <a href="/#">Office Center</a>
              </li>
              <li>
                <a href="/blog/news-and-events">News &amp; Events</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 width-20 mb-30">
            <h4 className="text-heading-5">Support</h4>
            <ul className="menu-footer mt-20">
              <li>
                <a href="/faqs">FAQs</a>
              </li>
              <li>
                <a href="/#">Editor Help</a>
              </li>
              <li>
                <a href="/#">Community</a>
              </li>
              <li>
                <a href="/#">Live Chatting</a>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
              <li>
                <a href="/#">Support Center</a>
                </li>
</ul>
</div>
<UsefulLinks /> 
</div>
</div>
<div className="footer-bottom"></div>
</footer>
);
}
export default Footer;
import React from "react";
import HomeLayout from "../layouts/home_layout";
import "../../assets/css/internal/privacy_policy.css";
import "../../assets/css/vendors/bootstrap.min.css"
const PrivacyPolicy = () => {
    
    return (
        
        <HomeLayout
    
      content={
        <>
         
        <div className="container-fluid">
            <h1>Privacy Policy</h1>
            <p><strong>PRIVACY POLICY</strong></p>
<p><strong>Last updated November 25, 2023</strong></p>
<p>This privacy notice for Tele Jobs Pro, LLC ("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"), describes how and why we might collect, store, use, and/or share ("<strong>process</strong>") your information when you use our services ("<strong>Services</strong>"), such as when you:</p>
<ul>
  <li>Visit our website at <a href="https://telejobspro.com">https://telejobspro.com</a>, or any website of ours that links to this privacy notice
  </li>
</ul>
<ul>
  <li>Engage with us in other related ways, including any sales, marketing, or events</li>
</ul>
<p><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at contact@telejobspro.com.</p>
<p><strong>SUMMARY OF KEY POINTS</strong></p>
<p><strong>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our&nbsp;</strong><a href="#toc"><strong>table of contents</strong></a><strong>&nbsp;below to find the section you are looking for.</strong></p>
<p><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about&nbsp;<a href="#personalinfo">personal information you disclose to us</a>.</p>
<p><strong>Do we process any sensitive personal information?</strong> We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Learn more about&nbsp;<a href="#sensitiveinfo">sensitive information we process</a>.</p>
<p><strong>Do we receive any information from third parties?</strong> We may receive information from public databases, marketing partners, social media platforms, and other outside sources. Learn more about&nbsp;<a href="#othersources">information collected from other sources</a>.</p>
<p><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about&nbsp;<a href="#infouse">how we process your information</a>.</p>
<p><strong>In what situations and with which types of parties do we share personal information?</strong> We may share information in specific situations and with specific categories of third parties. Learn more about&nbsp;<a href="#whoshare">when and with whom we share your personal information</a>.</p>
<p><strong>How do we keep your information safe?</strong> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about&nbsp;<a href="#infosafe">how we keep your information safe</a>.</p>
<p><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about&nbsp;<a href="#privacyrights">your privacy rights</a>.</p>
<p><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by visiting telejobspro.com, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
<p>Want to learn more about what we do with any information we collect?&nbsp;<a href="#toc">Review the privacy notice in full</a>.</p>
<p><strong>TABLE OF CONTENTS</strong></p>
<p><a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a></p>
<p><a href="#infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</a></p>
<p><a href="#legalbases">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></p>
<p><a href="#whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></p>
<p><a href="#cookies">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></p>
<p><a href="#sociallogins">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></p>
<p><a href="#inforetain">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a></p>
<p><a href="#infosafe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></p>
<p><a href="#infominors">9. DO WE COLLECT INFORMATION FROM MINORS?</a></p>
<p><a href="#privacyrights">10. WHAT ARE YOUR PRIVACY RIGHTS?</a></p>
<p><a href="#DNT">11. CONTROLS FOR DO-NOT-TRACK FEATURES</a></p>
<p><a href="#uslaws">12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></p>
<p><a href="#policyupdates">13. DO WE MAKE UPDATES TO THIS NOTICE?</a></p>
<p><a href="#contact">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></p>
<p><a href="#request">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></p>
<p><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></p>
<p><strong>Personal information you disclose to us</strong></p>
<p><strong>In Short:</strong><strong>&nbsp;</strong>We collect personal information that you provide to us.</p>
<p>We collect personal information that you voluntarily provide to us when you register on the Services,&nbsp;express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
<p><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
<ul>
  <li>names</li>
</ul>
<ul>
  <li>phone numbers</li>
</ul>
<ul>
  <li>email addresses</li>
</ul>
<ul>
  <li>mailing addresses</li>
</ul>
<ul>
  <li>job titles</li>
</ul>
<ul>
  <li>usernames</li>
</ul>
<ul>
  <li>passwords</li>
</ul>
<ul>
  <li>contact preferences</li>
</ul>
<ul>
  <li>contact or authentication data</li>
</ul>
<ul>
  <li>billing addresses</li>
</ul>
<ul>
  <li>debit/credit card numbers</li>
</ul>
<p><strong>Sensitive Information.</strong> When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</p>
<ul>
  <li>social security numbers or other government identifiers</li>
</ul>
<p><strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by __________. You may find their privacy notice link(s) here: __________.</p>
<p><strong>Social Media Login Data.&nbsp;</strong>We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called "<a href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" below.</p>
<p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
<p><strong>Information automatically collected</strong></p>
<p><strong>In Short:</strong><strong>&nbsp;</strong>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>
<p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
<p>Like many businesses, we also collect information through cookies and similar technologies.</p>
<p>The information we collect includes:</p>
<ul>
  <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services&nbsp;(such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</li>
</ul>
<ul>
  <li>Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
</ul>
<ul>
  <li>Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
</ul>
<p><strong>Information collected from other sources</strong></p>
<p><strong>In Short:&nbsp;</strong>We may collect limited data from public databases, marketing partners, social media platforms, and other outside sources.</p>
<p>In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, social media platforms,&nbsp;and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion.&nbsp;If you interact with us on a social media platform using your social media account (e.g., Facebook or Twitter), we receive personal information about you such as your name, email address, and gender. Any personal information that we collect from your social media account depends on your social media account's privacy settings.</p>
<p><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></p>
<p><strong>In Short:&nbsp;</strong>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>
<p><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong></p>
<ul>
  <li><strong>To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</strong>We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
</ul>
<ul>
  <li><strong>To deliver and facilitate delivery of services to the user.&nbsp;</strong>We may process your information to provide you with the requested service.</li>
</ul>
<ul>
  <li><strong>To respond to user inquiries/offer support to users.&nbsp;</strong>We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
</ul>
<ul>
  <li><strong>To send administrative information to you.&nbsp;</strong>We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
</ul>
<ul>
  <li><strong>To fulfill and manage your orders.&nbsp;</strong>We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</li>
</ul>
<ul>
  <li><strong>To enable user-to-user communications.&nbsp;</strong>We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
</ul>
<ul>
  <li><strong>To request feedback.&nbsp;</strong>We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
</ul>
<ul>
  <li>
    <strong>To send you marketing and promotional communications.&nbsp;</strong>We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "<a href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS?</a>" below.
  </li>
</ul>
<ul>
  <li><strong>To deliver targeted advertising to you.&nbsp;</strong>We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.</li>
</ul>
<ul>
  <li><strong>To post testimonials.</strong> We post testimonials on our Services that may contain personal information.</li>
</ul>
<ul>
  <li><strong>To protect our Services.</strong> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
</ul>
<ul>
  <li><strong>To administer prize draws and competitions.</strong> We may process your information to administer prize draws and competitions.</li>
</ul>
<ul>
  <li><strong>To evaluate and improve our Services, products, marketing, and your experience.</strong> We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.</li>
</ul>
<ul>
  <li><strong>To identify usage trends.</strong> We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
</ul>
<ul>
  <li><strong>To determine the effectiveness of our marketing and promotional campaigns.</strong> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</li>
</ul>
<ul>
  <li><strong>To comply with our legal obligations.</strong> We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.</li>
</ul>
<p><strong>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</strong></p>
<p><strong>In Short:&nbsp;</strong>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</p>
<p><strong>If you are located in Canada, this section applies to you.</strong></p>
<p>We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can&nbsp;<a href="#withdrawconsent">withdraw your consent</a>&nbsp;at any time.</p>
<p>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</p>
<ul>
  <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
</ul>
<ul>
  <li>For investigations and fraud detection and prevention</li>
</ul>
<ul>
  <li>For business transactions provided certain conditions are met</li>
</ul>
<ul>
  <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
</ul>
<ul>
  <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
</ul>
<ul>
  <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
</ul>
<ul>
  <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
</ul>
<ul>
  <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
</ul>
<ul>
  <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
</ul>
<ul>
  <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
</ul>
<ul>
  <li>If the information is publicly available and is specified by the regulations</li>
</ul>
<p><strong>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong></p>
<p><strong>In Short:</strong>&nbsp;We may share information in specific situations described in this section and/or with the following categories of third parties.</p>
<p><strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may share your data with third-party vendors, service providers, contractors, or agents ("<strong>third parties</strong>") who perform services for us or on our behalf and require access to such information to do that work. The categories of third parties we may share personal information with are as follows:</p>
<ul>
  <li>Cloud Computing Services</li>
</ul>
<ul>
  <li>Affiliate Marketing Programs</li>
</ul>
<ul>
  <li>Ad Networks</li>
</ul>
<ul>
  <li>Communication & Collaboration Tools</li>
</ul>
<ul>
  <li>Data Analytics Services</li>
</ul>
<ul>
  <li>Data Storage Service Providers</li>
</ul>
<ul>
  <li>Finance & Accounting Tools</li>
</ul>
<ul>
  <li>Government Entities</li>
</ul>
<ul>
  <li>Order Fulfillment Service Providers</li>
</ul>
<ul>
  <li>Payment Processors</li>
</ul>
<ul>
  <li>Performance Monitoring Tools</li>
</ul>
<ul>
  <li>Product Engineering & Design Tools</li>
</ul>
<ul>
  <li>Retargeting Platforms</li>
</ul>
<ul>
  <li>Sales & Marketing Tools</li>
</ul>
<ul>
  <li>Social Networks</li>
</ul>
<ul>
  <li>Testing Tools</li>
</ul>
<ul>
  <li>User Account Registration & Authentication Services</li>
</ul>
<ul>
  <li>Website Hosting Service Providers</li>
</ul>
<p>We also may need to share your personal information in the following situations:</p>
<ul>
  <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
</ul>
<ul>
  <li><strong>When we use Google Maps Platform APIs.</strong> We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). We obtain and store on your device ("cache") your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.</li>
</ul>
<ul>
  <li><strong>Affiliates.&nbsp;</strong>We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
</ul>
<ul>
  <li><strong>Business Partners.</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
</ul>
<ul>
  <li><strong>Other Users.</strong> When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</li>
</ul>
<p><strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong></p>
<p><strong>In Short:</strong>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</p>
<p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
<p><strong>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong></p>
<p><strong>In Short:&nbsp;</strong>If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</p>
<p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</p>
<p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>
<p><strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></p>
<p><strong>In Short:&nbsp;</strong>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
<p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than &nbsp; the period of time in which users have an account with us.</p>
<p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
<p><strong>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong></p>
<p><strong>In Short:&nbsp;</strong>We aim to protect your personal information through a system of organizational and technical security measures.</p>
<p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
<p><strong>9. DO WE COLLECT INFORMATION FROM MINORS?</strong></p>
<p><strong>In Short:</strong>&nbsp;We do not knowingly collect data from or market to children under 18 years of age.</p>
<p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at contact@telejobspro.com.</p>
<p><strong>10. WHAT ARE YOUR PRIVACY RIGHTS?</strong></p>
<p><strong>In Short:</strong>&nbsp;In some regions, such as Canada, you have rights that allow you greater access to and control over your personal information.&nbsp;You may review, change, or terminate your account at any time.</p>
<p>In some regions (like Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "<a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.</p>
<p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
<p><strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "<a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.</p>
<p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
<p><strong>Opting out of marketing and promotional communications:</strong><strong>&nbsp;</strong>You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or by contacting us using the details provided in the section "<a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
<p><strong>Account Information</strong></p>
<p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
<ul>
  <li>Contact us using the contact information provided.</li>
</ul>
<p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
<p><strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.</p>
<p>If you have questions or comments about your privacy rights, you may email us at contact@telejobspro.com.</p>
<p><strong>11. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
<p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
<p><strong>12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong></p>
<p><strong>In Short:&nbsp;</strong>If you are a resident of Utah or Virginia, you are granted specific rights regarding access to your personal information.</p>
<p><strong>What categories of personal information do we collect?</strong></p>
<p>We have collected the following categories of personal information in the past twelve (12) months:</p>
<table>
  <tbody>
    <tr>
      <td><strong>Category</strong></td>
      <td><strong>Examples</strong></td>
      <td><strong>Collected</strong></td>
    </tr>
    <tr>
      <td>
        <p>A. Identifiers</p>
      </td>
      <td>
        <p>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</p>
      </td>
      <td>
        <p>YES</p>
      </td>
    </tr>
  </tbody>
</table>
<table>
  <tbody>
    <tr>
      <td>
        <p>B. Protected classification characteristics under state or federal law</p>
      </td>
      <td>
        <p>Gender and date of birth</p>
      </td>
      <td>
        <p>YES</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>C. Commercial information</p>
      </td>
      <td>
        <p>Transaction information, purchase history, financial details, and payment information</p>
      </td>
      <td>
        <p>YES</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>D. Biometric information</p>
      </td>
      <td>
        <p>Fingerprints and voiceprints</p>
      </td>
      <td>
        <p>NO</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>E. Internet or other similar network activity</p>
      </td>
      <td>
        <p>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</p>
      </td>
      <td>
        <p>YES</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>F. Geolocation data</p>
      </td>
      <td>
        <p>Device location</p>
      </td>
      <td>
        <p>YES</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>G. Audio, electronic, visual, thermal, olfactory, or similar information</p>
      </td>
      <td>
        <p>Images and audio, video or call recordings created in connection with our business activities</p>
      </td>
      <td>
        <p>NO</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>H. Professional or employment-related information</p>
      </td>
      <td>
        <p>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</p>
      </td>
      <td>
        <p>YES</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>I. Education Information</p>
      </td>
      <td>
        <p>Student records and directory information</p>
      </td>
      <td>
        <p>NO</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>J. Inferences drawn from collected personal information</p>
      </td>
      <td>
        <p>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</p>
      </td>
      <td>
        <p>YES</p>
      </td>
    </tr>
    <tr>
      <td>K. Sensitive personal Information</td>
      <td>Account login information, citizenship or immigration status, drivers' licenses, passport numbers, personal data from a known child and social security numbers</td>
      <td>
        <p>YES</p>
      </td>
    </tr>
  </tbody>
</table>
<p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
<ul>
  <li>Category A - As long as the user has an account with us</li>
</ul>
<ul>
  <li>Category B - As long as the user has an account with us</li>
</ul>
<ul>
  <li>Category C - As long as the user has an account with us</li>
</ul>
<ul>
  <li>Category E - As long as the user has an account with us</li>
</ul>
<ul>
  <li>Category F - As long as the user has an account with us</li>
</ul>
<ul>
  <li>Category H - As long as the user has an account with us</li>
</ul>
<ul>
  <li>Category J - As long as the user has an account with us</li>
</ul>
<ul>
  <li>Category K - As long as the user has an account with us</li>
</ul>
<p>Category K information may be used, or disclosed to a service provider or contractor, for additional, specified purposes. You have the right to limit the use or disclosure of your sensitive personal information.</p>
<p>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</p>
<ul>
  <li>Receiving help through our customer support channels;</li>
</ul>
<ul>
  <li>Participation in customer surveys or contests; and</li>
</ul>
<ul>
  <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
</ul>
<p><strong>How do we use and share your personal information?</strong></p>
<p>Learn about how we use your personal information in the section, "<a href="#infouse">HOW DO WE PROCESS YOUR INFORMATION?</a>"</p>
<p>We collect and share your personal information through:</p>
<ul>
  <li>Targeting cookies/Marketing cookies</li>
</ul>
<ul>
  <li>Social media cookies</li>
</ul>
<ul>
  <li>Beacons/Pixels/Tags</li>
</ul>
<ul>
  <li>Click redirects: __________.</li>
</ul>
<ul>
  <li>Social media plugins: __________. We use social media features, such as a "Like" button, and widgets, such as a "Share" button, in our Services. Such features may process your Internet Protocol (IP) address and track which page you are visiting on our website. We may place a cookie to enable the feature to work correctly. If you are logged in on a certain social media platform and you interact with a widget or button belonging to that social media platform, this information may be recorded to your profile of such social media platform. To avoid this, you should log out from that social media platform before accessing or using the Services. Social media features and widgets may be hosted by a third party or hosted directly on our Services. Your interactions with these features are governed by the privacy notices of the companies that provide them. By clicking on one of these buttons, you agree to the use of this plugin and consequently the transfer of personal information to the corresponding social media service. We have no control over the essence and extent of these transmitted data or their additional processing.</li>
</ul>
<p><strong>Will your information be shared with anyone else?</strong></p>
<p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "<a href="#whoshare">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>"</p>
<p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.</p>
<p>We have disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:</p>
<ul>
  <li>Category A. Identifiers</li>
</ul>
<ul>
  <li>Category B. Characteristics of protected classifications under state or federal law</li>
</ul>
<ul>
  <li>Category C. Commercial information</li>
</ul>
<ul>
  <li>Category E. Internet or other electronic network activity information</li>
</ul>
<ul>
  <li>Category F. Geolocation data</li>
</ul>
<ul>
  <li>Category H. Professional or employment-related information</li>
</ul>
<ul>
  <li>Category J. Inferences drawn from collected personal information</li>
</ul>
<ul>
  <li>Category J. Sensitive personal information</li>
</ul>
<p>The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under "<a href="#whoshare">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>"</p>
<p>We have sold or shared the following categories of personal information to third parties in the preceding twelve (12) months:</p>
<ul>
  <li>Category E. Internet or other electronic network activity information</li>
</ul>
<ul>
  <li>Category F. Geolocation data</li>
</ul>
<ul>
  <li>Category H. Professional or employment-related information</li>
</ul>
<ul>
  <li>Category J. Inferences drawn from collected personal information</li>
</ul>
<p>The categories of third parties to whom we sold personal information are:</p>
<ul>
  <li>Ad Networks</li>
</ul>
<ul>
  <li>Affiliate Marketing Programs</li>
</ul>
<ul>
  <li>Data Analytics Services</li>
</ul>
<ul>
  <li>Retargeting Platforms</li>
</ul>
<ul>
  <li>Social Networks</li>
</ul>
<ul>
  <li>User Account Registration & Authentication Services</li>
</ul>
<p>The categories of third parties to whom we shared personal information with are:</p>
<ul>
  <li>User Account Registration & Authentication Services</li>
</ul>
<ul>
  <li>Retargeting Platforms</li>
</ul>
<ul>
  <li>Data Analytics Services</li>
</ul>
<ul>
  <li>Ad Networks</li>
</ul>
<ul>
  <li>Social Networks</li>
</ul>
<p><strong>Utah Residents</strong></p>
<p>This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.</p>
<ul>
  <li>Right to be informed whether or not we are processing your personal data</li>
</ul>
<ul>
  <li>Right to access your personal data</li>
</ul>
<ul>
  <li>Right to request deletion of your personal data</li>
</ul>
<ul>
  <li>Right to obtain a copy of the personal data you previously shared with us</li>
</ul>
<ul>
  <li>Right to opt out of the processing of your personal data if it is used for targeted advertising or the sale of personal data</li>
</ul>
<p>We sell personal data to third parties or process personal data for targeted advertising. You can opt out from the selling of your personal data or targeted advertising by disabling cookies in Cookie Preference Settings. To submit a request to exercise any of the other rights described above, please email contact@telejobspro.com or visit telejobspro.com.</p>
<p><strong>Virginia Residents</strong></p>
<p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>
<p>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</p>
<p>"Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.</p>
<p>"Sale of personal data" means the exchange of personal data for monetary consideration.</p>
<p>If this definition of "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.</p>
<p>Your rights with respect to your personal data</p>
<ul>
  <li>Right to be informed whether or not we are processing your personal data</li>
</ul>
<ul>
  <li>Right to access your personal data</li>
</ul>
<ul>
  <li>Right to correct inaccuracies in your personal data</li>
</ul>
<ul>
  <li>Right to request deletion of your personal data</li>
</ul>
<ul>
  <li>Right to obtain a copy of the personal data you previously shared with us</li>
</ul>
<ul>
  <li>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</li>
</ul>
<p>We sell personal data to third parties or process personal data for targeted advertising. Please see the following section to find out how you can opt out from further selling or sharing of your personal data for targeted advertising or profiling purposes.</p>
<p>Exercise your rights provided under the Virginia VCDPA</p>
<p>You can opt out from the selling of your personal data, targeted advertising, or profiling by disabling cookies in Cookie Preference Settings.&nbsp;You may contact us by email at contact@telejobspro.com or visit telejobspro.com.</p>
<p>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</p>
<p>Verification process</p>
<p>We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.</p>
<p>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.</p>
<p>Right to appeal</p>
<p>If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at contact@telejobspro.com. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">Attorney General to submit a complaint</a>.</p>
<p><strong>13. DO WE MAKE UPDATES TO THIS NOTICE?</strong></p>
<p><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
<p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
<p><strong>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong></p>
<p>If you have questions or comments about this notice, you may email us at contact@telejobspro.com or&nbsp;contact us by post at:</p>
<p>Tele Jobs Pro, LLC</p>
<p>30 n gould street</p>
<p>10</p>
<p>Sheridan, WY 82801</p>
<p>United States</p>
<p><strong>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</strong></p>
<p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: telejobspro.com.</p>
        </div>
        </>
      }
    />
  );
}; 

export default PrivacyPolicy;
import React, { Component } from "react";

import QuestionComponent from "./question-component";
class QualifyingQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeQuestion: 0,


            
            questions: [
                {
                    question: 'Are you in CA, CT, MD, MA, NY, OR, or WI?',
                    answers: [true, false],
                    questionType: 'radio',
                    questionNumber: 0,
                },
                {
                    question: 'Are you authorized to work in the United States without sponsorship?',
                    answers: [true, false],
                    questionType: 'radio',
                    questionNumber: 1,
                },
                {
                    question: 'Are you at least 18 years old?',
                    answers: [true, false],
                    questionType: 'radio',
                    questionNumber: 2,
                },
                {
                    question: 'Do you have Customer Service or Call Center Experience?',
                    answers: [true, false],
                    questionType: 'radio',
                    questionNumber: 3,
                },
                {
                    question: 'Have you worked or been registered with Arise?',
                    answers: [true, false],
                    questionType: 'radio',
                    questionNumber: 4,
                },
                {
                    question: 'Do you own a computer?',
                    answers: [true, false],
                    questionType: 'radio',
                    questionNumber: 5,
                },
                {
                    question: 'Are you physically present in the US?',
                    answers: [true, false],
                    questionType: 'radio',
                    questionNumber: 6,
                },
            ],
        };
    }
    
    render() {
        return (
            <section className="q_area my-3">
                <div className="q_wrapper">

              {this.state.questions.map((question, questionIndex) => (
                    <QuestionComponent key={questionIndex} question={question.question} answers={question.answers} questionType={question.questionType} questionNumber={question.questionNumber} activeQuestion={this.state.activeQuestion} />
              ))}

                </div>
            </section>
        );

    }
}

export default QualifyingQuestions;
import React from 'react';
import { Link } from 'react-router-dom';
import HorizontalLogoColor from '../assets/imgs/brand/TJPColorHoLogo.png';
function Header() {
    return (
        <header>
            <div className="container">
                <nav className="navbar navbar-expand-lg b g-body-tertiary shadow-md">

                    <div className="container-fluid">

                      <Link className="navbar-brand logo-container" to="/">
                        <img src={HorizontalLogoColor} loading="lazy"alt="Tele Jobs Pro, LLC's logo"/>
                        </Link>
                      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                      </button>

                      <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link page-scroll active" to="/">Home</Link>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link page-scroll" href="#benefits">Benefits</a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link page-scroll" href="#recruitment">Recruitment</a>
                              </li>
          
                              <li className="nav-item">
                                <a className="nav-link page-scroll" href="#about-us">About Us</a>
                              </li>
                              <li className="nav-item">
                                <a href="#opportunities" className="nav-link page-scroll">Opportunities</a>
                              </li>
                        </ul>

                        <span className="navbar-text">
                            <span><button type="button" className="btn button log-in-button">Log In</button></span>
                            <Link to="/register"><span><button type="button" className=" btn button sign-up-button">Sign Up</button></span></Link>
                        </span>

                      </div>
                    </div>

                  </nav>
            </div>
        </header>
    );
}

export default Header;

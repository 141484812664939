import React, { Component, Fragment } from 'react';


import Footer from '../../common/footer';
import Header from '../../common/header';
import '../../assets/css/style.css';

    class HomeLayout extends Component {

            constructor(props) {
                super(props);
                this.state = {};
            }

            render() {
                const { content } = this.props;
                return (
                    <Fragment>
                        <Header/>
                        <main>
                            {content}
                        </main>
                        <Footer />
                        
                    </Fragment>
                );
            
            };
        }
        
        

    export default HomeLayout;
    // END: be15d9bcejpp

import $ from 'jquery';

function validator(){
    const object=$("#password");
    //regex
    const length= object.val()?object.val().length>8:false;
    const uppercaseR= /[A-Z]{1}/.test(object.val());
    const lowercase= /[a-z]{1}/.test(object.val());
    const digit = /\d{1}/.test(object.val());
    setColor(length,".psl");
    setColor(uppercaseR,".psU");
    setColor(lowercase,".pslo");
    setColor(digit,".psd");
}

function setColor(boolean,object){
    object=$(object);
    if(boolean){
        object.css("color","#77CBB9");
    }else{
        object.css("color","initial");
    }
}

const qs = $(".q_details");
$(".q_title").click(function () {
  qs.slideUp();
  qs.prev().removeClass("active");
  $(this).next().slideDown();
  $(this).addClass("active");
  return false;
});

$(".q_details_pair").click(function (e) { 
    const parent=$(this).parent();
    const uncle_title=parent.siblings('.q_title');
    uncle_title.addClass("completed");
    parent.slideUp();
    const grandparent=parent.parent();
    grandparent.next().children(".q_details").slideDown();
});

$("#password").click(function (e) { 
    $("#requirements").show("slow");
});

$("#password").keyup(function (e) { 
    validator();
});

$("#confirm").click(function(e){
    $("#requirements").hide("slow");
});

$("#confirm").keyup(function (e) { 
    const pass=$("#password");
    const matched=$("#psmatch");
    const confirm=$("#confirm");
    if(pass.val()===confirm.val()){
        matched.show("slow");
        setColor(true,matched);
        confirm.trigger("blur");
    }else{
        matched.hide("slow");
        setColor(false,matched);
    }
});

$("input").change(function (e) { 
    const name= $("#legal_name").val();
    const email= $("#email").val();
    const resume=$("input[type='file']").val();
    const question1= $("input[name='q1']").is(':checked');
    const question2= $("input[name='q2']").is(':checked');
    const question3= $("input[name='q3']").is(':checked');
    const question4= $("input[name='q4']").is(':checked');
    const question5= $("input[name='q5']").is(':checked');
    const question6= $("input[name='q6']").is(':checked');
    const question7= $("input[name='q7']").is(':checked');
    const password=$("#psmatch").is(":visible");
    const formArr=[name,email,resume,question1,question2,question3,question4,question5,question6,question7,password];
    const valid=formArr.every(x=>{
        return x;
    });
   
    if(valid){
        $("#rbutton").attr("disabled", false);
    }else{
        $("#rbutton").attr("disabled", true);
    }
});

$("input[type='file']").change(function (e) { 
    fileValidation();
});

function fileValidation(){
    const file=$("input[type='file']");
    const ext= file.val().split(".").pop().toLowerCase();
    if($.inArray(ext,['pdf','docx','docm','doc'])===-1){
       file.val("");
         alert("Invalid file type");
    }
}

$("#rbutton").click(function() {
    $(this).animate({
      height: "+=10px",
      width: "+=10px",
    }, 200).animate({
      height: "-=10px",
      width: "-=10px",
    }, 200);
});

import React,{Suspense,lazy} from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import HomeLayout from '../layouts/home_layout';
import HeroSection from '../sections/hero-section';
import Preloader from '../../common/preloader';
const QuickDisclaimer = lazy(() => import('../sections/quick-disclaimer'));
const BusinessSection = lazy(() => import('../sections/business-info-banner'));
const Benefits = lazy(() => import('../sections/benefits-section'));
const ProcessSection = lazy(() => import('../sections/process-section'));
const IndustrySection = lazy(() => import('../sections/industry-section'));
const AboutSection = lazy(() => import('../sections/about-section'));
const ValuesSection = lazy(() => import('../sections/values-section'));


const Home = () => {
  return (
    <HomeLayout
    
      content={
        <>
                    <HelmetProvider>
                        <Helmet>
                            <meta charSet="UTF-8" />
                            <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1" />
                            <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
                            <meta name="msapplication-TileColor" content="#0E0E0E" />
                            <meta name="template-color" content="#0E0E0E" />
                            <meta name="msapplication-config" content="browserconfig.xml" />
                            <meta name="description" content="Index page" />
                            <meta name="keywords" content="index, page" />
                            <meta name="author" content="" />
                          
                            <title>
                                Tele Jobs Pro, LLC
                            </title>
                            
                            
                        </Helmet>
          </HelmetProvider>
          <Suspense fallback={<Preloader />}>
          <HeroSection />
          <BusinessSection />
          <Benefits />
          <ValuesSection />
          <ProcessSection />
          <IndustrySection  />
          <QuickDisclaimer />
          <AboutSection />
          </Suspense>
        </>
      }
    />
  );
};

export default Home;

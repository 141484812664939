import React from "react";
const UsefulLinks = () => {
    return (
      <div className="col-lg-3 width-16">
        <h4 className="text-heading-5">Useful links</h4>
        <ul className="menu-footer mt-20">
          <li>
            <a href="/#">Request an offer</a>
          </li>
          <li>
            <a href="/#">How it works</a>
          </li>
          <li>
            <a href="/pricing">Pricing</a>
          </li>
          <li>
            <a href="/#">Reviews</a>
          </li>
          <li>
            <a href="/#">Stories</a>
          </li>
        </ul>
      </div>
    );
  };
  
  export default UsefulLinks;